import React from "react";

import { Container, Wrapper } from "../../../components/util";
import {
  IntegrationsPageHeader,
  WhatIsIntegration,
  WhatIsInvoxy,
  IntegrationsNav,
} from "../../../components/site";
import Image from "../../../components/image";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";

import BullhornLogo from "../../../images/logos/Other/Bullhorn_Dark_Full.svg";

const Bullhorn = (props) => (
  <Layout>
    <Seo
      title="Bullhorn Integration | Karmly"
      description="Automatically send placements from Bullhorn to Karmly for online timesheets, invoicing and financial reporting."
      pathname={props.location.pathname}
    />
    <IntegrationsNav />
    <IntegrationsPageHeader
      category="APPLICANT TRACKING"
      title="Connect Karmly with Bullhorn"
      description="Automatically send new placements to Karmly."
    />
    <Container>
      <Wrapper>
        <h4 className="-textCenter">
          Add or edit clients, resources & placements in Bullhorn and it will
          seamlessly update in Karmly for timesheets & invoicing.
        </h4>
        <Image
          filename="Bullhorn_Feature.png"
          alt="Bullhorn + Karmly Integration"
          addShadow
        />
      </Wrapper>
    </Container>
    <WhatIsInvoxy />
    <WhatIsIntegration
      title="What is Bullhorn?"
      description="Bullhorn is the global leader in online recruitment agency software, providing the only fully integrated applicant tracking and customer relationship management system paired with social media recruiting and business development tools."
      integration="Bullhorn"
      link="https://www.bullhorn.com/"
      logo={BullhornLogo}
      details={[
        "Bullhorn’s web-based software is optimised to fit the way clients work, from anywhere and at anytime.",
        "Deep industry expertise, developed while working with over 2,000 staffing firms, to ensure our software delivers business success to our clients.",
        "Eliminate time-consuming manual data entry, improve productivity, and achieve the most efficient workflow possible.",
      ]}
    />
  </Layout>
);

export default Bullhorn;
